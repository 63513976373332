import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout";
import { setPassword } from "../../utils/api";
import { eraseAllCookies } from "../../utils/eraseAllCookies";
import { setCookie } from "../../utils/setCookie";
import "../../styles/register.scss";
import { UserSignupHeader } from "../../components/Headers/UserSignupHeader";

const Login = ({ location }: { location: any }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [verifCode, setVerifCode] = useState("");
  const [token, setToken] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const query = location.search
      .slice(1)
      .split("&")
      .reduce((acc, e) => {
        const d = e.split("=");
        return { ...acc, [d[0]]: d[1] };
      }, {});
    if (query.token && query.code) {
      setVerifCode(query.code);
      setToken(query.token);
    } else {
      navigate("/");
    }
  }, [location.search]);

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout header={<UserSignupHeader />}>
      <div className="set-password">
        <div className="set-password__header">{t("setup_password.title")}</div>
        <Row align="middle" justify="center">
          <Col>
            <Form
              layout="vertical"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={() => {
                setIsLoading(true);
                const { password } = form.getFieldsValue();
                setPassword({ token, verifCode, password })
                  .then(r => {
                    setIsLoading(false);
                    if (r.ok) {
                      eraseAllCookies();
                      setCookie("accessToken", token);
                      navigate("/register/setup-diligence-form");
                    } else {
                      message.error(t("setup_password.err_tok"));
                    }
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={12}>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={t("password.lbl")}
                    name="password"
                    style={{
                      marginBottom: 20,
                    }}
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (value && value.length >= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject(t("password.err.invalid"));
                        },
                      }),
                      {
                        required: true,
                        message: t("password.err.required"),
                      },
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    label={t("password_confirm.lbl")}
                    name="confirmPassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: t("password_confirm.err.required"),
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            t("password_confirm.err.invalid")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: 40,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  size="large"
                  className="main-btn"
                >
                  {t("continue")}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Login;
